.submittal-doc-preview {
    margin-top: 20px;
}

.submittal-doc-preview .submittal-doc-content-wrapper >.ant-space {
    max-width: 100%;
}

.submittal-doc-preview .btn-content-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 30px;
}

.submittal-doc-preview .preview {
    margin-top: 0px !important;
}

.submittal-doc-preview .btn-content-wrapper .next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0A1E5B;
    border: 1px solid #0A1E5B;
    color: #ffff;
    padding: 25px 40px;
    font-size: 16px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.6px;
}

.submittal-doc-preview .btn-content-wrapper .next-btn:hover {
    background-color: #0A1E5B;
    border: 1px solid #0A1E5B;
    color: #ffff;
}

.submittal-doc-preview .btn-content-wrapper .next-btn svg {
    margin-left: 20px;
}

.submittal-doc-preview .btn-content-wrapper .ant-btn {
    padding: 25px 15px !important;
    border-radius: 6px;
}

.submittal-doc-preview .btn-content-wrapper .next-btn svg g path {
    stroke: #ffff;
}

.submittal-doc-preview .btn-content-wrapper .back-btn svg {
    margin-right: 20px;
}

/* Reduce the visibility and size of textLayer and annotationLayer */
.textLayer,
.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent !important;
  opacity: 0; /* Hide the layers without disrupting layout */
}

/* Ensure that the textLayer and annotationLayer don't contribute to the document height */
.textLayer > div,
.annotationLayer > div {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1 !important; /* Adjust line height */
  height: 0 !important; /* Set height to 0 to minimize space */
}

/* Control spacing between PDF pages */
.react-pdf__Page__canvas {
  margin-bottom: 0 !important; /* Remove default margin between pages */
}

.react-pdf__Page {
  position: relative;
  margin-bottom: 10px; /* Adjust as needed to control space between pages */
}

.pdf-scroll-container {
    max-height: 620px;
    overflow-y: auto;
    overflow-x: auto;
    padding: 10px;
}

.submittal-doc-preview .pdf-scroll-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.submittal-doc-preview .pdf-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.submittal-doc-preview .pdf-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.submittal-doc-preview .pdf-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 1024px){
    .submittal-doc-preview .btn-content-wrapper .ant-btn {
        font-size: 13px;
    }
}

@media (max-width: 425px) {
    .submittal-doc-preview {
        padding:0px 20px;
    }
    .submittal-doc-preview .btn-content-wrapper {
        margin-top: 0px;
    }
    .submittal-doc-preview .btn-content-wrapper .ant-space {
        display: flex;
        flex-direction: column;
    }
    .submittal-doc-preview .btn-content-wrapper .ant-space .purple-btn {
        width: 280px;
    }
    .submittal-doc-preview .preview {
        padding: 0px;
    }
}

@media (max-width: 320px) {
    .submittal-doc-preview .btn-content-wrapper .ant-space .purple-btn {
        width: 200px;
    }
}
