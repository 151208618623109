 .ant-upload-wrapper .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
}

.ant-upload-wrapper .ant-upload-list-item-container {
    width: 153px;
    height: 111px;
    order: 2;
    margin: 18px 26px 6px 0;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 100%;
    height: 200px;
    border: none;
    order: 1;
    flex: 0 0 100%;
    background-color: #F4F6F8;
}

.upload-info-text {
    color: #BBC6D1;
    font-family: 'Poppins-Regular';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upload-info-text_container {
    margin-top: 20px;
}

.upload-content .ant-typography {
    color: #758CA1;
    font-family: 'Montserrat-Regular';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upload-content .link-button {
    color: #807CE0;
    font-family: 'FH-Oscar-Regular';
    font-size: 11px;
}

.upload-content .link-button:hover {
    color: #807CE0 !important;
}