.info-section .name .ant-typography {
    color: #9A9A9A;
    font-family: "FH-Oscar-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

.info-section .label {
    color: #000;
    font-family: 'Helvetica-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}
