.addresses-page .address-list-wrapper {
    margin-top: 25px;
    background: #ffff;
    border-radius: 10px;
    padding: 40px;
}

.addresses-page .address-list-wrapper .address-item:not(:last-child) {
    margin-bottom: 20px;
}

.addresses-page .address-list-wrapper .address-item {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 25px 40px;
}

.addresses-page .address-list-wrapper .address-item .address-title {
    font-family: 'FH-Oscar-Medium';
    color: #1A3173;
    font-size: 24px;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

.addresses-page .address-list-wrapper .address-item .address-type {
    font-family: 'FH-Oscar-Regular';
    color: #AAAAAA;
    font-size: 16px;
    margin-bottom: 20px;
    letter-spacing: 0.6px;
}

.addresses-page .address-list-wrapper .address-item .action-btns {
    display: flex;
    justify-content: flex-end;
}

.addresses-page .address-list-wrapper .address-item .action-btns .arrow-button,
.addresses-page .address-list-wrapper .address-item .action-btns .edit-button {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #E4E4E5;
    border: 1px solid #E4E4E5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addresses-page .address-list-wrapper .address-item .address-details.opened {
    display: block;
}

.addresses-page .address-list-wrapper .address-item .address-details {
    display: none;
    margin-top: 20px;
}

.addresses-page .address-list-wrapper .address-item .address-details .description {
    margin-bottom: 10px;
}

/* New address form (modal) */

.new-address-form .ant-form .ant-radio-group .ant-radio-wrapper span {
    font-size: 16px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
}

.new-address-form .ant-form .ant-radio-group .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #d9d9d9;
}

.new-address-form .ant-form .ant-radio-group .ant-radio-checked .ant-radio-inner {
    background-color: #807CE0;
    border-color: #807CE0;
}

.new-address-form .ant-form .ant-radio-group .ant-radio-wrapper:hover .ant-radio-checked .ant-radio-inner {
    border-color: #807CE0;
}

.new-address-form .ant-form .save-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}

.new-address-form .ant-form .save-btn-wrapper .purple-btn {
    height: 45px;
}

@media (max-width: 1024px) {
    .addresses-page .search-addresses .ant-col:nth-child(2) {
        display: flex;
        justify-content: flex-end;
    }
    .addresses-page .search-addresses .purple-btn {
        width: 200px;
    }
}

@media (max-width: 425px) {
    .addresses-page {
        padding: 20px;
    }
    .addresses-page .address-list-wrapper {
        padding: 20px;
    }
    .addresses-page .address-list-wrapper .address-item {
        padding: 20px;
    }
}