@import '../../../screens/UserPanel/Dashboard/style.css';
@import '../../../screens/UserPanel/Account/style.css';
@import '../../../screens/UserPanel/Address/style.css';
@import '../../../screens/UserPanel/Favorites/style.css';
@import '../../../screens/UserPanel/SubmittalManager/style.css';

.user-panel {
    background-color: #f3f3f3;
}

.user-panel .user-panel-main-content {
    padding: 170px 0px 100px 0px;
    max-width: 1680px;
    width: 87.5%;
    margin: 0 auto;
}

/* USER PANEL ANTD MENU */
.ant-menu.dashboard-menu {
    border-radius: 10px;
}

.ant-menu.dashboard-menu .ant-menu-item {
    height: 55px;
    width: 100%;
    margin: 0px;
    padding: 33px 0px;
}

.ant-menu.dashboard-menu .ant-menu-item:last-child {
    padding-bottom: 40px;
}

.ant-menu.dashboard-menu .ant-menu-item svg {
    width: 24px;
    height: 24px;
}

.ant-menu.dashboard-menu .ant-menu-item .ant-menu-title-content a {
    color: #0A1E5B;
    font-family: 'FH-Haydon-Regular';
}

.ant-menu.dashboard-menu .ant-menu-item-selected {
    background-color: #807CE0;
}

.ant-menu.dashboard-menu .ant-menu-item-selected .ant-menu-title-content a {
    color: #ffff;
}

.ant-menu.dashboard-menu .ant-menu-item-selected svg path {
    fill: #ffff;
}

.ant-menu.dashboard-menu .ant-menu-item-selected:nth-child(3) svg path,
.ant-menu.dashboard-menu .ant-menu-item-selected:nth-child(4) svg path,
.ant-menu.dashboard-menu .ant-menu-item-selected:nth-child(5) svg path {
    stroke: #ffff;
    fill: transparent !important;
}

.ant-menu.dashboard-menu .ant-menu-item-selected:nth-child(6) svg g rect {
    fill: #807CE0;
}

.ant-menu.dashboard-menu .ant-menu-item-selected:nth-child(6) svg g path {
    stroke: #ffff;
    fill: transparent !important;
}

.ant-menu.dashboard-menu .ant-menu-item:hover:not(.ant-menu-item-selected) {
    opacity: 0.8;
}

/* BUTTONS */
.ant-btn-default:disabled:hover {
    background: rgba(0, 0, 0, 0.04) !important;
    border-color: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.25) !important;
}
.gradient-btn {
    background: linear-gradient(131deg, #73E3E5 4.25%, #807CE0 97.69%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #ffff;
    font-family: 'FH-Oscar-Regular';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    border: 0;
}

.gradient-btn:hover {
    border:0 !important;
    color: #ffff !important;
    background: linear-gradient(131deg, #73E3E5 4.25%, #807CE0 97.69%) !important;
}

.purple-btn {
    border-radius: 10px;
    background: #807CE0;
    border: 1px solid #807CE0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    color: #ffff;
    font-family: 'FH-Oscar-Regular';
    font-size: 16px;
    letter-spacing: 0.6px;
}

.purple-btn:hover {
    border: 1px solid #807CE0 !important;
    color: #ffff !important;
    background: #807CE0 !important;
    opacity: 0.9;
}

/* Modal */
.ant-modal-root .ant-modal-mask,
.ant-modal-root .ant-modal-wrap {
    z-index: 10000;
}

.ant-select-dropdown.modal-select-dropdown {
    z-index: 20000 !important;
}

.new-address-form .ant-modal-content,
.address-list-form .ant-modal-content {
    padding: 30px 40px 20px 40px;
}

.new-address-form .ant-modal-content .ant-modal-close,
.next-step-modal .ant-modal-content .ant-modal-close,
.address-list-form .ant-modal-content .ant-modal-close {
    margin-top: 10px;
    margin-right: 20px;
}

.new-address-form .ant-modal-content .ant-modal-close:hover,
.next-step-modal .ant-modal-content .ant-modal-close:hover,
.address-list-form .ant-modal-content .ant-modal-close:hover,
.view-pdf-modal .ant-modal-content .ant-modal-close:hover {
    background-color: transparent;
}

.new-address-form .ant-modal-content .ant-modal-close-x svg path,
.next-step-modal .ant-modal-content .ant-modal-close-x svg path,
.address-list-form .ant-modal-content .ant-modal-close-x svg path,
.view-pdf-modal .ant-modal-content .ant-modal-close-x svg path {
    stroke: #1A3173;
}

.new-address-form h6,
.next-step-modal .ant-modal-content .modal-title {
    font-family: 'FH-Oscar-Regular';
    color: #1A3173;
    font-size: 25px;
    letter-spacing: 0.5px;
}

/* Search content */
.addresses-page .search-addresses .ant-input-outlined,
.submittal-page .search-project .ant-input-outlined,
.quotes-page .search-quotes .ant-input-outlined {
    border-radius: 10px;
    border-color: transparent;
    min-height: 45px;
    padding-left: 20px;
}
.addresses-page .search-addresses .ant-input-affix-wrapper-focused,
.submittal-page .search-project .ant-input-affix-wrapper-focused,
.quotes-page .search-quotes .ant-input-affix-wrapper-focused {
    border-color: transparent !important;
}

.addresses-page .search-addresses .purple-btn,
.submittal-page .search-project .purple-btn,
.quotes-page .search-quotes .purple-btn {
    height: 45px;
}

.addresses-page .search-addresses .purple-btn svg,
.submittal-page .search-project .purple-btn svg,
.quotes-page .search-quotes .purple-btn svg {
    margin-left: 10px;
}

/* card list */
.submittal-page .project-list > .project-item,
.quotes-page .project-list > .project-item {
    margin-top: 30px;
}

.submittal-page .project-list .project-item .project-item-header,
.quotes-page .project-list .project-item .project-item-header {
    background-color: #807CE0;
    padding: 20px 20px 20px 40px;
    border-radius: 10px 10px 0px 0px;
}

.submittal-page .project-list .project-item .project-item-header .project-date,
.quotes-page .project-list .project-item .project-item-header .project-date {
    font-family: 'Helvetica-Regular';
    color: #ffff;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.submittal-page .project-list .project-item .project-item-header .project-name,
.quotes-page .project-list .project-item .project-item-header .project-name {
    font-family: 'FH-Oscar-Bold';
    color: #ffff;
    font-size: 28px;
    letter-spacing: 0.3px;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns,
.quotes-page .project-list .project-item .project-item-header .project-action-btns {
    display: flex;
    justify-content: flex-end;
}

.quotes-page .project-list .project-item .project-item-header .project-action-btns >.ant-space{
    flex-wrap: wrap;
    justify-content: flex-end;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0A1E5B;
    border-color: #0A1E5B;
    color: #ffff;
    font-size :14px;
    font-family: 'FH-Oscar-Light';
    letter-spacing: 0.5px;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn svg,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn svg {
    margin-right: 10px;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.edit-btn svg,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn svg {
    width: 20px;
    height: 20px;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.edit-btn svg g path,
.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.delete-btn svg g path,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn svg g path {
    stroke: #ffff;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.delete-btn svg g,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.delete-btn svg g {
    opacity: 1;
}

.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.copy-btn svg,
.submittal-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.delete-btn svg,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.copy-btn svg,
.quotes-page .project-list .project-item .project-item-header .project-action-btns .ant-btn.delete-btn svg  {
    width: 15px;
    height: 15px;
}

.submittal-page .project-list .project-item .project-item-body,
.quotes-page .project-list .project-item .project-item-body {
    background-color: #ffff;
    padding: 20px 40px 40px 40px;
    border-radius: 0px 0px 10px 10px;
}

.submittal-page .project-list .project-item .project-item-body .table-wrapper,
.quotes-page .project-list .project-item .project-item-body .table-wrapper {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header,
.quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header {
    padding: 20px;
}

.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .table-title,
.quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .table-title {
    color: #0A1E5B;
    font-size: 18px;
}

.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters,
.quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters {
    display: flex;
    justify-content: flex-end;
}

.submittal-page .project-list .project-item .project-item-body .project-item-footer,
.quotes-page .project-list .project-item .project-item-body .project-item-footer {
    display: flex;
    justify-content: flex-end;
}

.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn,
.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn {
    height: 45px;
    border-color: #0A1E5B;
    color: #0A1E5B;
    font-family: 'FH-Oscar-Regular';
    font-size: 16px;
    letter-spacing: 0.6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn:hover,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn:hover,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn:hover,
.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn:hover {
    background-color: #0A1E5B;
    color: #fff;
}

.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn svg,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn svg,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn svg,
.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn svg {
    margin-left: 10px;
}

.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn:hover svg path,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn:hover svg path,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn:hover svg path,
.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn:hover svg path {
    fill: #fff;
}

.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn svg g path,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn svg g path,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn svg g path,
.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn svg g path {
    stroke: #0A1E5B;
}

.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn:hover svg g path,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn:hover svg g path,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn:hover svg g path,
.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn:hover svg g path {
    stroke: #fff;
}

.submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn svg path,
.submittal-page .project-list .project-item .project-item-body .empty-submittal .add-submittal-btn svg path,
.quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn svg path,
.quotes-page .project-list .project-item .project-item-body .empty-quote .add-quote-btn svg path {
    fill: #0A1E5B;
}

.submittal-page .project-list .project-item .project-item-body .empty-submittal .info,
.quotes-page .project-list .project-item .project-item-body .empty-quote .info {
    font-family: 'FH-Oscar-Regular';
    font-size: 18px;
    color: #ACB2C5;
    letter-spacing: 0.5px;
}

.submittal-page .project-list .project-item .project-item-body .empty-submittal,
.quotes-page .project-list .project-item .project-item-body .empty-quote {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.submittal-page .ant-table .ant-table-tbody .quote-btn.quote-requested,
.quotes-page .ant-table .ant-table-tbody .convert-btn.convert-requested {
    background-color: rgba(2, 188, 119, 0.17);
    border-color: #02BC77;
    color: #02BC77;
}

.submittal-page .ant-table .ant-table-tbody .quote-btn.quote-requested:hover,
.quotes-page .ant-table .ant-table-tbody .convert-btn.convert-requested:hover {
    border-color: #02BC77;
    color: #02BC77; 
}

/* Table */
.favorites-page .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child,
.submittal-page .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child,
.quotes-page .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px;
}

.favorites-page .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child,
.submittal-page .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child,
.quotes-page .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0px;
}

.favorites-page .ant-table-thead >tr>th,
.favorites-page .ant-table-thead >tr>td,
.favorites-page .ant-table-thead >tr>th::before,
.submittal-page .ant-table-thead >tr>th,
.submittal-page .ant-table-thead >tr>td,
.submittal-page .ant-table-thead >tr>th::before,
.quotes-page .ant-table-thead >tr>th,
.quotes-page .ant-table-thead >tr>td,
.quotes-page .ant-table-thead >tr>th::before {
    background-color: #ffff !important;
}

.favorites-page .ant-table-thead >tr>th,
.submittal-page .ant-table-thead >tr>th,
.quotes-page .ant-table-thead >tr>th {
    border-top: 2px solid #f0f0f0;
}

.favorites-page .ant-table .ant-table-column-title,
.submittal-page .ant-table .ant-table-column-title,
.quotes-page .ant-table .ant-table-column-title {
    color: #707070;
    font-family: "FH-Oscar-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.favorites-page .ant-table .ant-table-tbody>tr>td:first-child,
.submittal-page .ant-table .ant-table-tbody>tr>td:first-child,
.quotes-page .ant-table .ant-table-tbody>tr>td:first-child {
    padding: 16px 20px;
}

.favorites-page .ant-table .ant-table-tbody>tr>td,
.submittal-page .ant-table .ant-table-tbody>tr>td,
.quotes-page .ant-table .ant-table-tbody>tr>td {
    font-family: 'FH-Oscar-Regular';
    font-size: 16px;
    letter-spacing: 0.5px;
}

.favorites-page .ant-table .ant-table-thead >tr>th .ant-checkbox-wrapper .ant-checkbox-inner,
.favorites-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-wrapper .ant-checkbox-inner,
.submittal-page .ant-table .ant-table-thead >tr>th .ant-checkbox-wrapper .ant-checkbox-inner,
.submittal-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-wrapper .ant-checkbox-inner,
.quotes-page .ant-table .ant-table-thead >tr>th .ant-checkbox-wrapper .ant-checkbox-inner,
.quotes-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-wrapper .ant-checkbox-inner {
    border-radius: 2.1px;
    border: 0.7px solid #9C9C9B;
}

.favorites-page .ant-table .ant-table-thead >tr>th .ant-checkbox-checked .ant-checkbox-inner,
.favorites-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-checked .ant-checkbox-inner,
.favorites-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-checked .ant-checkbox-inner:hover,
.submittal-page .ant-table .ant-table-thead >tr>th .ant-checkbox-checked .ant-checkbox-inner,
.submittal-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-checked .ant-checkbox-inner,
.submittal-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-checked .ant-checkbox-inner:hover,
.quotes-page .ant-table .ant-table-thead >tr>th .ant-checkbox-checked .ant-checkbox-inner,
.quotes-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-checked .ant-checkbox-inner,
.quotes-page .ant-table .ant-table-tbody >tr>td .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #0A1E5B !important;
    border-color: #0A1E5B !important;
}

.favorites-page .ant-table .ant-table-thead .ant-checkbox-indeterminate .ant-checkbox-inner:after,
.favorites-page .ant-table .ant-table-tbody .ant-checkbox-indeterminate .ant-checkbox-inner:after,
.submittal-page .ant-table .ant-table-thead .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #0A1E5B;
}

.favorites-page .ant-table .ant-table-tbody .ant-btn,
.submittal-page .ant-table .ant-table-tbody .ant-btn,
.quotes-page .ant-table .ant-table-tbody .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #707070;
    border: 1px solid #EBEBEB;
    font-size: 14px;
    font-family: 'FH-Oscar-Light';
    letter-spacing: 0.5px;
}

.favorites-page .ant-table .ant-table-tbody .ant-btn:hover,
.submittal-page .ant-table .ant-table-tbody .ant-btn:hover,
.quotes-page .ant-table .ant-table-tbody .ant-btn:hover {
    border-color: #d9d9d9;
    color: #707070;
}

.favorites-page .ant-table .ant-table-tbody .ant-btn svg,
.submittal-page .ant-table .ant-table-tbody .ant-btn svg,
.quotes-page .ant-table .ant-table-tbody .ant-btn svg {
    margin-right: 10px;
}

.submittal-page .ant-table .ant-table-tbody .ant-btn.edit-btn svg,
.quotes-page .ant-table .ant-table-tbody .ant-btn.edit-btn svg {
    width: 18px;
    height: 18px;
}

.submittal-page .ant-table .ant-table-tbody .ant-btn.download-btn svg,
.submittal-page .ant-table .ant-table-tbody .ant-btn.duplicate-btn svg,
.submittal-page .ant-table .ant-table-tbody .ant-btn.delete-btn svg,
.submittal-page .ant-table .ant-table-tbody .ant-btn.quote-btn #Group70_64,
.quotes-page .ant-table .ant-table-tbody .ant-btn.download-btn svg,
.quotes-page .ant-table .ant-table-tbody .ant-btn.duplicate-btn svg,
.quotes-page .ant-table .ant-table-tbody .ant-btn.delete-btn svg,
.quotes-page .ant-table .ant-table-tbody .ant-btn.convert-btn .submittal-svg {
    width: 12px;
    height: 12px;
}

.submittal-page .ant-table .ant-table-tbody .ant-btn.delete-btn svg g,
.quotes-page .ant-table .ant-table-tbody .ant-btn.delete-btn svg g {
    opacity: 1;
}

.submittal-page .ant-table .ant-table-tbody .ant-btn.delete-btn svg g path,
.quotes-page .ant-table .ant-table-tbody .ant-btn.delete-btn svg g path {
    stroke: #707070;
}

.submittal-page .ant-table .ant-table-tbody .ant-btn.quote-btn .btn-content-wrapper,
.quotes-page .ant-table .ant-table-tbody .ant-btn.convert-btn .btn-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.submittal-page .ant-table .ant-table-tbody .ant-btn svg g path,
.quotes-page .ant-table .ant-table-tbody .ant-btn svg g path {
   stroke: #707070;
}

/* Filters */
.favorites-page .filters .ant-select .ant-select-selector:hover,
.favorites-page .filters .ant-input-affix-wrapper:hover,
.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-select .ant-select-selector:hover,
.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-picker-outlined,
.quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-picker-outlined {
    border-color: #DADDE6 !important;
}

.quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-picker-outlined {
    width: 100%;
}

.favorites-page .filters .ant-input-affix-wrapper .ant-input::placeholder,
.favorites-page .filters .ant-select .ant-select-selection-placeholder,
.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-select .ant-select-selection-placeholder,
.submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-picker .ant-picker-input > input::placeholder,
.quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-picker .ant-picker-input > input::placeholder {
    color: #9C9C9B;
    font-family: "FH-Oscar-Regular";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
}

/* Antd picker */
.ant-picker-dropdown .ant-picker-body thead tr th,
.ant-picker-dropdown .ant-picker-body tbody tr td {
    font-family: 'FH-Haydon-Regular';
    color: #04164B;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    border-radius: 50%;
    background-color: #04164B;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
    border: 1px solid #04164B;

}

.ant-picker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
.ant-picker-cell-inner {
    background: transparent;
}

/* Pagination style */
.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-item-active,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-item-active {
    width: 32px;
    height: 31px;
    border-radius: 4px;
    border: 1px solid #807CE0;
    background-color: #807CE0;
}

.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-item-active a,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-item-active a {
    color: #ffff !important;
}

.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-item:not(.ant-pagination-item-active),
.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-prev,
.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-next,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-item:not(.ant-pagination-item-active),
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-prev,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-next {
    border: 1px solid #9C9C9B;
}

.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-item a,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-item a {
    color: #9C9C9B;
    font-family: "Helvetica-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-item,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-item {
    margin-inline-end: 20px;
}

.new-submittal-page .add-products .content .pagination-content .ant-pagination-item:not(.ant-pagination-item-active):hover,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: transparent;
}

/* .new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-options .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-options .ant-select-focused .ant-select-selector {
    border-color: #d9d9d9 !important;
} */

.new-submittal-page .add-products .content .pagination-content .ant-pagination .ant-pagination-options,
.favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination .ant-pagination-options {
    display: none;
}

.user-panel-main-content .dashboard-menu,
.user-panel-main-content .account-content-wrapper,
.user-panel-main-content .addresses-page .address-list-wrapper,
.user-panel-main-content .favorites-page,
.user-panel-main-content .dashboard-content-wrapper {
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

/* popoconfirm style */
.ant-popconfirm.delete-item-popconfirm .ant-popconfirm-title,
.ant-popconfirm.delete-item-popconfirm .ant-popconfirm-description {
    font-family: 'FH-Oscar-Regular';
}
.ant-popconfirm.delete-item-popconfirm .ant-popconfirm-buttons .ant-btn-default:hover {
    border-color:#0A1E5B;
    color: #0A1E5B;
}

.ant-popconfirm.delete-item-popconfirm .ant-popconfirm-buttons .ant-btn-primary {
    border-color:#0A1E5B;
    background: #0A1E5B;
    color: #ffff;
}

/* textarea */
#note, #description {
    height: 100px;
}

@media (min-width: 2500px) {
    .user-panel .user-panel-main-content {
        padding: 170px 0px 50px 0px;
        width: 70.5%;
    }
}

@media (min-width: 2000px) {
    .user-panel .user-panel-main-content {
        padding: 170px 0px 50px 0px;
        max-width: none;
   }
}

@media (max-width: 1199px) {
    .user-panel .user-panel-main-content {
        padding: 170px 0px 50px 0px;
        width: 95%;
    }
}

@media (max-width: 1024px) {
    .user-panel .user-panel-main-content {
        padding: 170px 0px 50px 0px;
    }
    .submittal-page .search-project .purple-btn,
    .quotes-page .search-quotes .purple-btn {
        width: 200px;
    }
    .submittal-page .search-project .ant-col:nth-child(2),
    .quotes-page .search-quotes .ant-col:nth-child(2) {
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 991px) {
    .user-panel .user-panel-main-content >.ant-row {
        flex-direction: column;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .user-panel .user-panel-main-content >.ant-row >.ant-col {
        width: 100%;
        max-width: 100%;
        padding: 0 !important;
    }
}

@media (max-width: 768px) {
    .user-panel .user-panel-main-content {
        padding: 170px 0 50px;
    }
    .submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters {
        display: block;
    }
    .submittal-page .project-list .project-item-header .project-action-btns .ant-space {
        display: block;
    }
    .submittal-page .project-list .project-item-header .project-action-btns .ant-space .ant-space-item:not(:last-child) {
        margin-bottom: 10px;
    }
}

@media (max-width: 425px) {
    .user-panel .user-panel-main-content {
        padding: 170px 0px 50px 0px;
    }
    .ant-menu.dashboard-menu {
        border-radius: 0px;
    }
    .ant-menu.dashboard-menu .ant-menu-item {
        border-radius: 0px;
    }
    .submittal-page,
    .quotes-page {
        padding: 0px;
    }
    .submittal-page .project-list .project-item .project-item-header,
    .quotes-page .project-list .project-item .project-item-header {
        padding: 20px;
    }
    .submittal-page .project-list .project-item .project-item-header .project-name,
    .quotes-page .project-list .project-item .project-item-header .project-name {
        font-size: 20px;
    }
    .submittal-page .project-list .project-item .project-item-body,
    .quotes-page .project-list .project-item .project-item-body {
        padding: 20px;
    }
    .submittal-page .project-list .project-item .project-item-body .table-wrapper,
    .quotes-page .project-list .project-item .project-item-body .table-wrapper {
        border: none;
    }
    .submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header,
    .quotes-page .project-list .project-item .project-item-body .table-wrapper .table-main-header {
        padding: 0px;
        margin-bottom: 30px;
    }
    .submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-space {
        display: block;
    }
    .submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-space .ant-space-item:first-child {
        margin-bottom: 15px;
    }
    .submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-select,
    .submittal-page .project-list .project-item .project-item-body .table-wrapper .table-main-header .filters .ant-picker {
        width: 100%;
    }
    .submittal-page .project-list .project-item .project-item-body .project-item-footer .add-submittal-btn {
        padding: 20px 25px;
    }
    .quotes-page .project-list .project-item .project-item-body .project-item-footer .add-quote-btn {
        padding: 20px 22px;
    }

    .loader.user-panel-loader {
        top: 50%;
        left: 40%;
    }
}