.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    border: 1px dashed #C0C4C8;
    border-radius: 4px;
    background-color: #fafafa;
}

.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .cover-upload-content-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .cover-upload-content-vertical .icon-wrapper {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .cover-upload-content-vertical .icon-wrapper svg {
    width: 25px;
    height: 25px;
}

.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .cover-upload-content-vertical p {
    font-size: 13px;
    font-family: 'FH-Oscar-Regular';
    color: #2E2E2E;
    letter-spacing: 0.5px;
}

.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:hover {
    border: 1px dashed #C0C4C8;
}

.cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .ant-image .ant-image-img {
    width: 100%;
    height:63px;
}

.cover-upload-wrapper .ant-upload-picture-card-wrapper.cover-upload .ant-upload.ant-upload-select-picture-card {
    width: 100%;
}
 
.cover-upload-wrapper .delete-img-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: -10px;
    background: #F4F6F8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-upload-wrapper .delete-img-icon .ant-btn:hover {
    background-color: transparent;
}

.cover-upload-wrapper .cover-upload-content .ant-btn .ant-space .ant-space-item p,
.cover-upload-wrapper .cover-upload-content span {
    color: #474849;
    font-family: 'FH-Oscar-Regular';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
    letter-spacing: 0.5px;
}

.cover-upload-wrapper .cover-upload-content .ant-btn {
    padding: 0px 3px;
}

/* .cover-upload-wrapper .cover-upload-content .ant-btn span {
    font-weight: 700 !important;
} */

.cover-upload-wrapper .cover-upload-content .upload-file-content {
    color: #474849;
    font-family: 'FH-Haydon-Regular';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cover-upload-wrapper .cover-upload-content .upload-file-content span {
    font-weight: 700 !important;
}

