.favorites-page {
    background: #ffff;
    border-radius: 10px;
    padding: 40px;
}

.favorites-page .favorites-content-wrapper {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding-bottom: 20px;
}

.favorites-page .favorites-content-wrapper .table-main-header {
    padding: 20px;
}

.favorites-page .favorites-content-wrapper .page-title {
    color: #0A1E5B;
    font-size: 18px;
}

.favorites-page .favorites-content-wrapper .filters {
    display: flex;
    justify-content: flex-end;
}

.favorites-page .favorites-content-wrapper .filters .ant-select-selector {
    width: 200px;
}

.favorites-page .filters .ant-input-affix-wrapper .ant-input-prefix svg {
    width: 12px;
    height: 12px;
}

.favorites-page .filters .ant-input-affix-wrapper .ant-input-prefix svg path {
    fill: #2B2B2C;
}

.favorites-page .extra-footer-btn-wrapper {
    margin-top: 20px;
}

.favorites-page .extra-footer-btn-wrapper .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
    padding: 20px 40px;
}

.favorites-page .extra-footer-btn-wrapper .ant-btn svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.favorites-page .extra-footer-btn-wrapper .add-btn,
.favorites-page .extra-footer-btn-wrapper .add-btn:hover {
    background-color:#807CE0;
    border: none;
    color: #ffff;
}

.favorites-page .extra-footer-btn-wrapper .remove-btn,
.favorites-page .extra-footer-btn-wrapper .remove-btn:hover {
    background-color:rgba(255, 35, 102, 0.17);
    border: none;
    color: #FF2366;
}

.favorites-page .extra-footer-btn-wrapper .remove-btn svg g path {
    stroke: #FF2366;
}

.favorites-page .extra-footer-btn-wrapper .remove-btn svg g path {
    fill: #FF2366;
}

.favorites-page .extra-footer-btn-wrapper .view-btn,
.favorites-page .extra-footer-btn-wrapper .view-btn:hover {
    background-color:rgba(26, 49, 115, 0.17);
    border: none;
    color: #707070;
}

.favorites-page .extra-footer-btn-wrapper .pagination-content {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .favorites-page .extra-footer-btn-wrapper .add-btn,
    .favorites-page .extra-footer-btn-wrapper .remove-btn {
        padding: 25px 44px;
        font-size: 16px;
    }
}

@media (max-width: 425px) {
    .favorites-page {
        border-radius: 0px;
    }
    .favorites-page .favorites-content-wrapper {
        border: none;
        padding: 0px;
    }
    .favorites-page .favorites-content-wrapper .table-main-header {
        padding: 0px;
        margin-bottom: 30px;
    }
    .favorites-page .favorites-content-wrapper .table-main-header .filters {
        display: block;
    }
    .favorites-page .favorites-content-wrapper .table-main-header .filters .ant-select-selector {
        width: 100%;
        margin-bottom: 15px;
    }
    .favorites-page .extra-footer-btn-wrapper .pagination-content .ant-pagination {
        display: flex;
    }
    .favorites-page .extra-footer-btn-wrapper .add-btn,
    .favorites-page .extra-footer-btn-wrapper .remove-btn {
        padding: 20px 35px;
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .favorites-page .extra-footer-btn-wrapper .add-btn,
    .favorites-page .extra-footer-btn-wrapper .remove-btn {
        padding: 20px 23px;
        font-size: 16px;
    }
}

@media (max-width: 320px) {
    .favorites-page .extra-footer-btn-wrapper .add-btn,
    .favorites-page .extra-footer-btn-wrapper .remove-btn {
        padding: 10px 14px;
        font-size: 14px;
    }
}