.description .label {
    font-size: 14px;
    color: #1A3173;
    margin-right: 4px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.9px;
}

.description .content {
    font-size: 14px;
    color: #7F7F7F;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.9px;
}
