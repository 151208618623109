.preview {
    margin-top: 20px;
}

.preview .header {
    background-color: #807CE0;
    border-radius: 10px 10px 0px 0px;
    padding: 20px;
}

.preview .header p {
    font-size: 22px;
    color: #ffff;
    text-transform: uppercase;
    font-family: 'FH-Oscar-SemiBold';
    letter-spacing: 0.5px;
}

.preview .content {
    background-color: #ffff;
    border-radius: 0px 0px 10px 10px;
    padding: 20px;
}

.preview .content .title {
    font-size: 22px;
    color: #0A1E5B;
    font-family: 'FH-Oscar-Medium';
    letter-spacing: 0.5px;
}

.preview .content .project-info .project-name,
.preview .content .submittal-info .submittal-name {
    font-size: 16px;
    color: #0A1E5B;
    font-family: 'Helvetica-Regular';
    letter-spacing: 0.5px;
}

.preview .content .project-info .project-desc {
    font-size: 14px;
    color: #0A1E5B;
    font-family: 'Helvetica-Regular';
    letter-spacing: 0.5px;
}

.preview .content .empty-content {
    font-size: 14px;
    color: #BBC6D1;
    font-family: 'FH-Haydon-Regular';
    margin-top: 5px;
}

.preview .content .view-details-btn {
    color: #807CE0;
    font-size: 16px;
    font-family: 'Helvetica-Regular';
    padding: 0px;
    letter-spacing: 0.5px;
}

.preview .content .view-details-btn span {
    text-decoration: underline;
}

.preview .content .view-details-btn:hover {
    background-color: transparent;
}

.preview .content .submitted-by {
    font-size: 16px;
    font-family: 'Helvetica-Regular';
    letter-spacing: 0.5px;
    color: #0A1E5B;
    margin-bottom: 10px;
    margin-top: 10px;
}

.preview .content #title-3 {
    margin-bottom: 10px;
}

.preview .content .info-section {
    margin-bottom: 10px;
}

.preview .content .product-info .ant-space {
    width: 100%;
}

.preview .content .product-info .info-section .name .ant-typography,
.preview .content .product-info .length {
    font-size: 14px;
    color: #000;
    font-family: 'Helvetica-Regular';
}

.preview .content .product-info .info-section .label,
.preview .content .product-info .length > span {
    font-size: 12px;
    color: #595959;
    font-family: 'FH-Oscar-Regular';
}

.preview .content .product-info .remove-icon-wrapper {
    display: flex;
    justify-content: flex-end;
}

.preview .content .product-info .remove-icon {
    width: 22px;
    height: 22px;
    background-color: #E4E4E5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview .content .product-info .remove-icon:hover {
    cursor: pointer;
}

.preview .content .product-info .remove-icon svg {
    width: 15px;
    height: 15px;
}

.preview .content .product-info .remove-icon svg path {
    stroke: #93959B;
}

.preview .content .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.preview .content .button-wrapper .purple-btn {
    border-radius: 5px;
    padding: 20px 20px;
    font-size: 15px;
    letter-spacing: 0.6px;
}

.preview .content .button-wrapper .purple-btn.edit-btn {
    margin-left: 15px;
}

.preview .content .button-wrapper .purple-btn svg {
    margin-left: 10px;
    width: 16px;
    height: 16px;
}

.preview .content .button-wrapper .purple-btn svg g path {
    stroke: #ffff;
}

@media (max-width: 1024px) {
    .preview .content .button-wrapper .more-products-btn,
    .preview .content .button-wrapper .edit-btn {
        padding: 20px 10px;
    }
    .preview .content .product-info .remove-icon-wrapper {
        display: block;
    }
}

@media (max-width: 768px) {
    .preview .content .product-info .remove-icon-wrapper {
        display: flex;
        justify-content: flex-end; 
    }
}

@media (max-width: 425px) {
    .preview {
        padding: 0px 20px;
    }
    .preview .content .button-wrapper .more-products-btn,
    .preview .content .button-wrapper .edit-btn {
        padding: 20px 20px;
    }
}

@media (max-width: 320px) {
    .preview .content {
        padding: 20px 20px 120px 20px;
    }
    .preview .content .button-wrapper {
        display: block;
    }
    .preview .content .button-wrapper .more-products-btn {
        margin-bottom: 15px;
        float: right;
    }
    .preview .content .button-wrapper .edit-btn {
        float: right;
        width: 100px;
    }
    .preview .content .product-info .remove-icon-wrapper {
        display: block;
    }
}