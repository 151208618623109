.dashboard-content-wrapper {
    background: #ffff;
    border-radius: 10px;
    padding: 40px;
}

.dashboard-content-wrapper .dashboard-content {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 60px;
}

.dashboard-content-wrapper .dashboard-content .user-info .text {
    font-family: 'FH-Haydon-Regular';
    color: #1A3173;
    font-size: 32px;
}

.dashboard-content-wrapper .horizontal-menu {
    margin-top: 80px;
}

.dashboard-content-wrapper .horizontal-menu .ant-col {
    width: 20%;
}

.dashboard-content-wrapper .horizontal-menu .menu-item {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 30px;
    display: block;
    text-align: center;
    height: 180px;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover {
    background: #807CE0;
    cursor: pointer;
}

.dashboard-content-wrapper .horizontal-menu .menu-item .ant-badge .ant-badge-count {
    background: #807CE0;
    box-shadow: none;
    color: #ffff;
    transform: translate(30%, -10%);
}

.dashboard-content-wrapper .horizontal-menu .menu-item .ant-badge svg,
.dashboard-content-wrapper .horizontal-menu .menu-item svg {
    width: 45px;
    height: 45px;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover .ant-badge .ant-badge-count {
    background: #0A1E5B;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover #submittals-svg path {
    fill: #ffff;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover #job-quotes-svg g path,
.dashboard-content-wrapper .horizontal-menu .menu-item:hover #favorites-svg path,
.dashboard-content-wrapper .horizontal-menu .menu-item:hover #address-svg path {
    stroke: #ffff;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover #account-svg path {
    fill: #ffff;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover #account-svg g rect {
    fill: #807CE0;
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover #account-svg g path {
    stroke: #ffff;
    fill: transparent !important;
}

.dashboard-content-wrapper .horizontal-menu .menu-item p {
    font-size: 18px;
    margin-top: 20px;
    color: #0A1E5B;
    font-family: 'FH-Haydon-Regular';
}

.dashboard-content-wrapper .horizontal-menu .menu-item:hover p {
    color: #ffff;
}

@media (max-width: 1800px) {
    .dashboard-content-wrapper .horizontal-menu .ant-col {
        width: 25%;
    }
}

@media (max-width: 1440px) {
    .dashboard-content-wrapper .horizontal-menu .ant-col {
        width: 33.33%;
    }
}

@media (max-width: 1024px) {
    .dashboard-content-wrapper .horizontal-menu .ant-col {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .dashboard-content-wrapper .dashboard-content {
        padding: 30px;
    }
    .dashboard-content-wrapper .horizontal-menu .ant-col {
        width: 50%;
    }
}

@media (max-width: 576px) {
    .dashboard-content-wrapper .dashboard-content {
        padding: 30px;
    }

    .dashboard-content-wrapper .dashboard-content .user-info {
        flex-direction: column;
        text-align: center;
    }

    .dashboard-content-wrapper .horizontal-menu .ant-col {
        width: 100%;
    }
}


@media (max-width: 425px) {
    .dashboard-content-wrapper {
        border-radius: 0px;
    }
    .dashboard-content-wrapper .dashboard-content {
        border: none;
        padding: 0px;
    }
}