.authentication-page {
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.authentication-page .auth-container {
    margin-top: 180px;
    margin-bottom: 120px;
    background-color: #ffff;
    border-radius: 10px;
    padding: 30px;
    max-width: 1135px;
    width: 60%;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.authentication-page .auth-container.register {
    width: 75%;
    max-width: 1425px;
    padding: 49px 40px;
}

.authentication-page .auth-container .auth-form-header {
    margin-bottom: 50px;
}

.authentication-page .auth-container .auth-form-header h4 {
    color: #0A1E5B;
    font-family: 'FH-Haydon-Regular';
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 1.9px;
}

.authentication-page .auth-container .new-password-form .auth-form-header h4 {
    font-size: 36px;
}

.authentication-page .auth-container .auth-form-header span {
    font-family: 'FH-Haydon-Regular';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.28px;
    color: #0A1E5B;
    opacity: 0.62;
}

.authentication-page .auth-container .ant-form .ant-form-item .ant-form-item-label {
    color: #0A1E5B;
    font-family: 'FH-Haydon-Regular';
}

.authentication-page .auth-container .login-form .ant-form .ant-form-item .ant-input-affix-wrapper,
.authentication-page .auth-container .new-password-form .ant-form .ant-form-item .ant-input-affix-wrapper {
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid #9C9C9B;
    padding: 11px 0px;
}

.authentication-page .auth-container .login-form .ant-form .ant-form-item .ant-input-affix-wrapper-focused,
.authentication-page .auth-container .new-password-form .ant-form .ant-form-item .ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.authentication-page .auth-container .login-form .ant-form .ant-form-item input::placeholder,
.authentication-page .auth-container .new-password-form .ant-form .ant-form-item input::placeholder {
    color: rgba(10, 30, 91, 0.5);
    font-family: 'FH-Haydon-Regular';
}

.authentication-page .auth-container .ant-form .ant-form-item .ant-btn.link-btn {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    color: #807CE0;
    padding: 0px;
    font-family: 'FH-Oscar-Regular';
}

.authentication-page .auth-container .ant-form .ant-form-item .back-to-login-btn,
.authentication-page .auth-container .ant-form .ant-form-item .ant-btn.resend-code-btn {
    justify-content: center !important;
}

.authentication-page .auth-container .ant-form .ant-btn.submit-btn {
    background: linear-gradient(131deg, #73E3E5 4.25%, #807CE0 97.69%);
    border: 0;
    height: 55px;
    border-radius: 5px;
    font-size: 20px;
    font-family: 'FH-Oscar-Regular';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    letter-spacing: 0.3px;
    box-shadow: none;
}

.authentication-page .auth-container .ant-form .ant-btn.submit-btn:hover {
    background-image: linear-gradient(300deg, #807CE0, #73E3E5 37%, #807CE0) !important;
}

.authentication-page .auth-container .section-divider-vertical span,
.authentication-page .auth-container .section-divider-horizontal span {
    color: #cfcfcb;
    font-family: 'FH-Haydon-Regular';
}

.authentication-page .auth-container .section-divider-vertical {
    display: block;
}

.authentication-page .auth-container .section-divider-horizontal {
    display: none;
}

.authentication-page .auth-container .section-divider-vertical .ant-divider-vertical {
    height: 15em;
}

.authentication-page .auth-container.register .section-divider-vertical .ant-divider-vertical {
    height: 30em;
}

.authentication-page .auth-container.register a {
    color: #807CE0;
}

.authentication-page .auth-container.register .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
    border-color: #DDE4EB;
}

.authentication-page .auth-container.register .ant-checkbox-checked .ant-checkbox-inner,
.authentication-page .auth-container.register .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #807CE0;
    border-color: #807CE0;
}

.authentication-page .auth-container.register .ant-form .ant-input-affix-wrapper {
    border-radius: 10px;
    border: 2px solid #DDE4EB;
    height: 55px;
}

.authentication-page .auth-container .register-section {
    background-color: #807CE0;
    padding: 24px 34px;
    border-radius: 10px;
    margin-top: 22px;
}

.authentication-page .auth-container .login-section {
    background-color: #0A1E5B;
    padding: 24px 34px;
    border-radius: 10px;
    margin-top: 22px;
}

.authentication-page .auth-container .register-section .ant-space .ant-space-item:first-child span,
.authentication-page .auth-container .login-section .ant-space .ant-space-item:first-child span {
    font-family: 'FH-Haydon-Regular';
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 1.9px;
}

.authentication-page .auth-container .register-section .ant-space .ant-space-item:nth-child(2) span,
.authentication-page .auth-container .login-section .ant-space .ant-space-item:nth-child(2) span {
    color: #ffff;
    font-family: 'FH-Haydon-Regular';
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1.28px;
    opacity: 0.77;
}

.authentication-page .auth-container .register-section .ant-btn,
.authentication-page .auth-container .login-section .ant-btn {
    border-color: #ffff;
    color: #ffff;
    height: 55px;
    font-size: 18px;
    padding: 10px 50px;
    border-radius: 5px;
    font-family: 'FH-Haydon-Regular';
    margin-top: 10px;
    letter-spacing: 0.7px;
}

.authentication-page .auth-container .register-section .ant-btn.register-btn {
    background-color: #807CE0;
}

.authentication-page .auth-container .register-section .ant-btn.register-btn:hover{
    background-color: #0a1e5b;
    border-color: transparent;
}

.authentication-page .auth-container .login-section .ant-btn.login-btn {
    background-color: #0A1E5B;
}

.authentication-page .auth-container .login-section .ant-btn.login-btn:hover {
    background-color: #807ce0;
    border-color: transparent;
}

.authentication-page .auth-container .styles_react-code-input-container__tpiKG {
    width: auto !important;
    margin-bottom: 50px;
}

.authentication-page .auth-container .styles_react-code-input-container__tpiKG.numbers-filled-error {
    margin-bottom: 10px;
}

.authentication-page .auth-container .styles_react-code-input-container__tpiKG input {
    width: 30px !important;
    height: 40px !important;
    border: none;
    border-bottom: 2px solid #A4AAB7;
    border-radius: 0px;
}

.authentication-page .auth-container .styles_react-code-input-container__tpiKG input:not(:last-child) {
    margin-right: 20px;
}

.authentication-page .auth-container .styles_react-code-input-container__tpiKG input:focus {
    border-bottom: 2px solid #807CE0;
}

.authentication-page .auth-container .styles_react-code-input-container__tpiKG input[value]:not([value=""]) {
    border-bottom: 2px solid #2fe479;
}
.authentication-page .auth-container .authentication-form-second-step .ant-form-item {
    margin-bottom: 10px;
}
.authentication-page .auth-container .authentication-form-second-step .otp-error {
    color: #ff0000;
    font-family: 'FH-Haydon-Regular';
    font-size: 14px;
    margin-bottom: 30px;
}
.authentication-page .auth-container .authentication-form-second-step .resend-otp-msg-success {
    text-align: center;
    color: #0A1E5B;
    opacity: 0.62;
    font-family: 'FH-Haydon-Regular';
    font-size: 14px;
    margin-bottom: 20px;
}

@media (max-width: 1599px){
    .authentication-page .auth-container{
        width: 75%;
    }
}

@media (max-width: 1024px) {
    .authentication-page .auth-container {
        width: 90%;
    }
    .authentication-page .auth-container.register {
        width:90%;
    }
}

@media (max-width: 991px) {
    .authentication-page .auth-container .section-divider-vertical{
        display: none;
    }
    .authentication-page .auth-container .section-divider-horizontal {
        display: flex;
    }
    .authentication-page .auth-container{
        flex-direction: column;
    }
    .authentication-page .auth-container >.ant-col{
        width: 100%;
        max-width: 100%;
    }
    
}

@media (max-width: 768px) {
    .authentication-page .auth-container {
        width: 95%;
    }
    .authentication-page .auth-container.register {
        width:90%;
    }
    .authentication-page .auth-container.register .section-divider-vertical {
        display: none;
    }
    .authentication-page .auth-container.register .section-divider-horizontal {
        display: flex;
    }
    .authentication-page .auth-container .styles_react-code-input-container__tpiKG input:not(:last-child) {
        margin-right: 19px;
    }
}

@media (max-width: 575px){
    .authentication-page .auth-container.register {
        padding: 40px 15px;
    }
    .authentication-page .auth-container{
        padding: 30px 15px;
    }
}

@media (max-width: 425px) {
    .authentication-page .auth-container,
    .authentication-page .auth-container.register {
        width: 100%;
        border-radius: 0px;
    }
    .authentication-page .auth-container .section-divider-vertical {
        display: none;
    }
    .authentication-page .auth-container .section-divider-horizontal {
        display: flex;
    }
    .authentication-page .auth-container .styles_react-code-input-container__tpiKG input:not(:last-child) {
        margin-right: 31px;
    }
    .authentication-page .auth-container .register-form-first-step h4 {
        font-size: 36px;
    }
}

@media (max-width: 375px) {
    .authentication-page .auth-container .styles_react-code-input-container__tpiKG input:not(:last-child) {
        margin-right: 20px;
    }
    .authentication-page .auth-container .register-form-first-step h4 {
        font-size: 30px;
    }
}

@media (max-width: 320px) {
    .authentication-page .auth-container .new-password-form h4 {
        font-size: 25px;
    }
    .authentication-page .auth-container .register-section .ant-space .ant-space-item:first-child span {
        font-size: 30px;
    }
    .authentication-page .auth-container .register-section .ant-btn {
        padding: 10px 20px;
    }
    .authentication-page .auth-container .styles_react-code-input-container__tpiKG input:not(:last-child) {
        margin-right: 10px;
    }
    .authentication-page .auth-container .register-form-first-step h4 {
        font-size: 24px;
    }
}