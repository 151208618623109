.custom-form-item {
    margin-bottom: 0 !important;
}
.custom-form-item input:not(.ant-input-lg):not(.ant-input-sm) {
    line-height: 100%;
}
.custom-form-item .ant-form-item-label {
    position: absolute;
    padding: 0 !important;
    z-index: 2;
    transform: translate(8px, 14px) scale(1);
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
}
.custom-form-item .ant-form-item-label label {
    background: #ffff;
    padding: 0px 8px;
    color: #758ca1;
}
.custom-form-item .ant-form-item-label > label::after {
    content: none;
}
.custom-form-item.active .ant-form-item-label, .custom-form-item.filled .ant-form-item-label {
    transform: translate(7px, -10px) scale(0.857);
}
.custom-form-item.active .ant-form-item-label label, .custom-form-item.filled .ant-form-item-label label {
    height: 100%;
}
.custom-form-item:hover .ant-input, .custom-form-item:hover .ant-input-affix-wrapper, .custom-form-item:hover .ant-select, .custom-form-item:hover .ant-picker {
    border-color: #0A1E5B;
}
.custom-form-item.active .ant-form-item-label label, .custom-form-item:hover .ant-form-item-label label {
    color: #0A1E5B;
}
