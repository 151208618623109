
.loading-screen.visible {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.55);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.loading-screen.visible .loading-spinner {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #0A1E5B;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.new-submittal-page .form-title,
.new-submittal-page .page-title {
    font-size: 32px;
    font-family: 'FH-Oscar-Regular';
    color: #0A1E5B;
    letter-spacing: 0.5px;
}

.new-submittal-page .form-title.display-none {
    display: none;
}

.new-submittal-page .project-info,
.new-submittal-page .submittal-info,
.new-submittal-page .add-products,
.new-submittal-page .quote-request-confirmation-page,
.new-submittal-page .cover-letter-upload {
    margin-top: 20px;
}

.new-submittal-page .project-info .content,
.new-submittal-page .submittal-info .content-wrapper,
.new-submittal-page .quote-request-confirmation-page .content,
.new-submittal-page .cover-letter-upload .content {
    background-color: #ffff;
    border-radius: 10px;
}

.new-submittal-page .project-info .content,
.new-submittal-page .submittal-info .content-wrapper .content,
.new-submittal-page .cover-letter-upload .content {
    padding: 40px;
}

.project-info .content .switch-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-info .content .switch-content span {
    font-size: 16px;
    color: #7F8B97;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
}

.new-submittal-page .submittal-info .content-wrapper .content .tooltip-label {
    display: flex;
}

.new-submittal-page .submittal-info .content-wrapper .content .tooltip-label .svg-wrapper {
    margin-left: 10px;
    margin-top: -10px;
}

.new-submittal-page .submittal-info .content-wrapper .content .tooltip-label .svg-wrapper svg path,
.new-submittal-page .submittal-info .content-wrapper .content .tooltip-label .svg-wrapper svg circle {
    fill: #807CE0;
}

.ant-tooltip.tooltip-info .ant-tooltip-inner,
.ant-tooltip.tooltip-info .ant-tooltip-arrow:before {
    background-color: #0A1E5B;
}

.new-submittal-page .submittal-info .content-wrapper .ant-divider-horizontal {
    margin: 0px;
}

.new-submittal-page .submittal-info .content-wrapper .footer {
    padding: 40px 40px 20px 40px;
}

.new-submittal-page .submittal-info .content-wrapper .footer span {
    color: #7F8B97;
    font-size: 14px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
}

.new-submittal-page .submittal-info .content-wrapper .footer span a {
    color: #807CE0;
    text-decoration: underline;
}

.new-submittal-page .submittal-info .content-wrapper .footer .ant-checkbox-wrapper .ant-checkbox-inner,
.new-submittal-page .add-products .content .ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-color: #d9d9d9 !important;
}

.new-submittal-page .submittal-info .content-wrapper .footer .ant-checkbox-checked .ant-checkbox-inner,
.new-submittal-page .add-products .content .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #807CE0;
    border-color: #807CE0;
}

.new-submittal-page .submittal-info .content .form-section-title p {
    color: #0A1E5B;
    font-size: 18px;
    font-family: 'FH-Oscar-Medium';
    margin-bottom: 10px;
    letter-spacing: 0.5px;
}

.new-submittal-page .submittal-info .content .change-address-btn {
    border-radius: 6px;
    height: 40px;
    font-family: 'FH-Oscar-Regular';
    font-size: 16px;
    letter-spacing: 0.6px;
}

.new-submittal-page .submittal-info .content .separator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-submittal-page .submittal-info .content .separator svg {
    margin-top: 25px;
}

.new-submittal-page .submittal-info .content .switch-text {
    font-size: 18px;
    color: #7F8B97;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
}

.new-submittal-page .submittal-info .content .switch-text.active {
    color: #0A1E5B;
}

.new-submittal-page .submittal-info .content .ant-switch,
.new-submittal-page .project-info .content .ant-switch {
    background-color: #DDE4EB;
}

.new-submittal-page .submittal-info .content .ant-switch .ant-switch-handle::before,
.new-submittal-page .project-info .content .ant-switch .ant-switch-handle::before {
    background-color: #0A1E5B;
}

.new-submittal-page .quote-request-confirmation-page {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-submittal-page .quote-request-confirmation-page .content {
    padding: 40px;
    max-width: 1000px;
    width: 1000px;
}

/* next step modal style */
.next-step-modal .ant-modal-content {
    padding: 40px;
}

.next-step-modal .ant-modal-content .button-wrapper {
    margin-top: 20px;
}

.next-step-modal .ant-modal-content .button-wrapper .purple-btn {
    border-radius: 6px;
    padding: 20px 40px;
}

.next-step-modal .ant-modal-content .button-wrapper .or-divider {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-step-modal .ant-modal-content .button-wrapper .or-divider span {
    margin: 0px 10px;
    color: #B3BCD6;
}

.new-submittal-page .quote-request-confirmation-page .content > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-submittal-page .quote-request-confirmation-page .content > div:first-child {
    margin-bottom: 30px;
}

.new-submittal-page .quote-request-confirmation-page .content p {
    color: #0A1E5B;
    font-size: 38px;
    font-family: 'FH-Oscar-Regular';
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 1.2;
}

.new-submittal-page .quote-request-confirmation-page .content span {
    color: #807CE0;
    font-size: 38px;
    font-family: 'FH-Oscar-Regular';
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.new-submittal-page .quote-request-confirmation-page .content .ant-divider-horizontal {
    width: 20%;
    min-width: 20%;
}

.new-submittal-page .quote-request-confirmation-page .content .ant-divider {
    border-block-start: 2px solid #D2D9EF;
}

.new-submittal-page .quote-request-confirmation-page .content .purple-btn {
    padding: 30px 40px;
    margin-top: 20px;
}

.new-submittal-page .quote-request-confirmation-page .content .purple-btn span {
    color: #ffff;
    font-size: 20px;
    font-family: 'FH-Oscar-Regular';
    text-transform: capitalize;
    letter-spacing: 0.3px;
}

.new-submittal-page .quote-request-confirmation-page .content .purple-btn svg {
    margin-left: 20px;
}

.new-submittal-page .add-products .search-content .ant-input-outlined {
    border-radius: 10px;
    border-color: transparent;
    min-height: 55px;
    padding-left: 20px;
}

.new-submittal-page .add-products .content {
    background-color: #ffff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.new-submittal-page .add-products .content {
    padding: 40px 20px;
}

.new-submittal-page .add-products .content .favorite-products-btn {
    margin-bottom: 15px;
    border: 1px solid #807CE0;
    color: #807CE0;
    font-size: 15px;
    font-family: 'FH-Oscar-Regular';
}

.new-submittal-page .add-products .content .favorite-products-btn.active {
    background-color: #807CE0;
    color: #ffff;
}

.new-submittal-page .add-products .content .favorite-products-btn:hover {
    border-color: #807CE0;
}

/* antd tree style */
.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode,
.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode-motion.ant-motion-collapse-leave.ant-motion-collapse-leave-active.ant-motion-collapse,
.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode-motion.ant-motion-collapse-appear.ant-motion-collapse-appear-active.ant-motion-collapse {
    width: 100% !important;
    padding: 0 0 18px 0;
}
/* .new-submittal-page .add-products .content .ant-tree .ant-tree-treenode {
    padding: 0 0 18px 0;
} */
.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher {
    order: 3;
    margin-left: auto !important;
    /* margin-left: 20px; */
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-checkbox {
    margin-top: 2px;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-checkbox .ant-tree-checkbox-inner,
.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-checkbox .ant-tree-checkbox-inner:hover {
    border-color: #CBC9C9;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-checkbox-checked .ant-tree-checkbox-inner:hover {
    background-color: #0A1E5B !important;
    border-color: #0A1E5B !important;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    background-color: #0A1E5B !important;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-title {
    font-family: "FH-Oscar-Regular";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-title .parent-title {
    color: #0A1E5B;
    font-size: 18px;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-treenode .ant-tree-title .child-title {
    color: #676D7F;
    font-size: 16px;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_open:hover,
.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_close:hover {
    background-color: transparent;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon.arrow-down {
    background-color: #F5F6F8;
    border: 1px solid #F5F6F8;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_open .ant-tree-switcher-icon.arrow-up {
    background-color: #ffff;
    border: 1px solid #0A1E5B;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
    transform: rotate(0deg);
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_open .ant-tree-switcher-icon.minus-icon,
.new-submittal-page .add-products .content .ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon.plus-icon {
    width: 15px;
    height: 15px;
    margin-left: 2.8px;
}

.new-submittal-page .add-products .content .ant-tree .ant-tree-checkbox+span:hover,
.new-submittal-page .add-products .content .ant-tree .ant-tree-checkbox+span.ant-tree-node-selected {
    background: none;
}

.new-submittal-page .add-products .content .ant-divider-vertical {
    height: 100%;
    margin-inline: 0px;
}

.new-submittal-page .add-products .content .ant-checkbox-group {
    width: 100%;
    margin-bottom: 80px;
}

.new-submittal-page .add-products .content .ant-checkbox-group .ant-checkbox-wrapper span {
    font-size: 14px;
    font-family: 'FH-Oscar-Regular';
    color: #0A1E5B;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.new-submittal-page .add-products .content .pagination-content {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 20px;
}

.new-submittal-page .project-summary .info {
    margin-top: 10px;
    color: #646464;
    font-size: 16px;
    font-family: 'FH-Oscar-LightItalic';
    letter-spacing: 0.5px;
}

.new-submittal-page .project-summary .info svg,
.new-submittal-page .project-summary .project-name svg {
    margin-bottom: 3px;
}

.new-submittal-page .project-summary .project-name {
    font-size: 14px;
    font-family: 'FH-Haydon-Regular';
    margin-top: 10px;
}

.new-submittal-page .project-summary .project-name {
    color: #0A1E5B;
}

.new-submittal-page .project-summary .project-name .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 25px;
    color: #707070;
    font-family: 'FH-Oscar-Light';
    font-size: 14px;
    letter-spacing: 0.5px;
}

.new-submittal-page .project-summary .project-name .ant-btn:hover {
    color: #BBC6D1;
}

.new-submittal-page .project-summary .project-name .ant-btn svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.new-submittal-page .project-summary .project-name .ant-btn svg g path {
    stroke: #707070;
}

.new-submittal-page .project-summary .table-wrapper {
    margin-top: 20px;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-thead >tr,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr {
    z-index: 1 !important;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-thead >tr>th {
    background-color: #807CE0;
    color: #ffff;
    font-size: 18px;
    font-family: 'FH-Oscar-Bold';
    letter-spacing: 0.5px;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-thead >tr>th::before {
    display: none;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-thead >tr>th p {
    color: #ffff;
    font-size: 18px;
    font-family: 'FH-Oscar-Bold';
    letter-spacing: 0.5px;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-thead >tr>th span {
    color: #ffff;
    font-family: 'Helvetica-Regular';
    font-size: 16px;
    letter-spacing: 0.5px;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td {
    font-size: 16px;
    font-family: 'FH-Oscar-Medium';
    letter-spacing: 0.5px;
    color: #000;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .info-section .name .ant-typography {
    font-size: 16px;
    font-family: 'FH-Oscar-Medium';
    letter-spacing: 0.5px;
    color: #000;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .info-section .label {
    font-size: 14px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
    color: #000;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-select,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-input-number {
    width: 100%;
}

/* .new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-select .ant-select-selector,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-select .ant-select-selector:hover,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-input-number,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-input-number:hover {
    border-color: #6684DE;
} */

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-select,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .ant-input-number {
    min-height: 40px !important;
    border-radius: 6px;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .pdf-btn-view:hover {
    background-color: transparent;
}

.ant-select-dropdown.table-select .ant-divider {
    margin: 8px 0px;
}

.ant-select-dropdown.table-select .ant-checkbox-wrapper .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-color: #d9d9d9 !important;
}

.ant-select-dropdown.table-select .ant-checkbox-checked .ant-checkbox-inner,
.ant-select-dropdown.table-select .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #807CE0 !important;
    border-color: #807CE0 !important;
}

.ant-select-dropdown.table-select .ant-input {
    border-color: #d9d9d9;
}

.ant-select-dropdown.table-select .ant-btn {
    padding: 10px;
    background-color: #807CE0;
    border: 1px solid #807CE0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-select-dropdown.table-select .ant-btn:hover {
    background-color: #807CE0;
    border: 1px solid #807CE0;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .not-applicable {
    background-color: #E5E4F8;
    padding: 10px 20px 10px 12px;
    color: rgba(10, 30, 91, 0.5);
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .product-unit {
    font-size: 18px;
    font-family: 'Helvetica-Regular';
    letter-spacing: 0.5px;
    color: #0A1E5B;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr:nth-child(even) {
    background-color: #F5F6F8;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .delete-btn:hover,
.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .order-btn:hover {
    background-color: transparent;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper .ant-table-tbody >tr>td .delete-btn svg {
    width: 20px;
    height: 20px;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper.empty-table .ant-table-tbody > tr {
    background-color: #ffff;
}

.new-submittal-page .project-summary .table-wrapper .ant-table-wrapper.empty-table .ant-space span {
    color: #a4b0c9;
}

.new-submittal-page .project-info .btn-content-wrapper,
.new-submittal-page .submittal-info .btn-content-wrapper,
.new-submittal-page .add-products .next-content-wrapper,
.new-submittal-page .project-summary .btn-content-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.new-submittal-page .cover-letter-upload .btn-content-wrapper .ant-row {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.new-submittal-page .project-info .btn-content-wrapper .next-btn,
.new-submittal-page .submittal-info .btn-content-wrapper .next-btn,
.new-submittal-page .add-products .next-content-wrapper .next-btn,
.new-submittal-page .project-summary .btn-content-wrapper .next-btn,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0A1E5B;
    border: 1px solid #0A1E5B;
    color: #ffff;
    padding: 25px 40px;
    font-size: 16px;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.6px;
}

.new-submittal-page .add-products .next-content-wrapper .next-btn:hover,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .next-btn:hover,
.new-submittal-page .project-info .btn-content-wrapper .next-btn:hover,
.new-submittal-page .project-summary .btn-content-wrapper .next-btn:hover,
.new-submittal-page .submittal-info .btn-content-wrapper .next-btn:hover {
    opacity: 0.9;
}

.new-submittal-page .project-info .btn-content-wrapper .next-btn svg,
.new-submittal-page .submittal-info .btn-content-wrapper .next-btn svg,
.new-submittal-page .add-products .next-content-wrapper .next-btn svg,
.new-submittal-page .project-summary .btn-content-wrapper .next-btn svg,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .next-btn svg {
    margin-left: 20px;
}

.new-submittal-page .project-info .btn-content-wrapper .back-btn svg,
.new-submittal-page .submittal-info .btn-content-wrapper .back-btn svg,
.new-submittal-page .add-products .next-content-wrapper .back-btn svg,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .back-btn svg,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .skip-btn svg {
    margin-right: 20px;
}

.new-submittal-page .cover-letter-upload .btn-content-wrapper .skip-btn svg {
    margin-left: 20px;
}

.new-submittal-page .project-info .btn-content-wrapper .back-btn,
.new-submittal-page .submittal-info .btn-content-wrapper .back-btn,
.new-submittal-page .add-products .btn-content-wrapper .back-btn,
.new-submittal-page .project-summary .btn-content-wrapper .add-more-products-btn,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .back-btn,
.new-submittal-page .cover-letter-upload .btn-content-wrapper .skip-btn {
    border-radius: 6px;
    padding: 25px 20px;
}

.new-submittal-page .project-summary .btn-content-wrapper .add-more-products-btn svg {
    margin-left: 20px;

}

.address-list-form .ant-modal-content .address-list-content {
    margin-top: 30px;
}

/* .address-list-form .ant-modal-content .address-list-content .ant-radio-button-wrapper {
    padding: 15px;
} */

.address-list-form .ant-modal-content .address-list-content .address-option {
    height: auto;
}

.address-list-form .ant-modal-content .address-list-content .ant-switch,
.address-list-form .ant-modal-content .address-list-content .ant-switch {
    background-color: #DDE4EB;
}

.address-list-form .ant-modal-content .address-list-content .ant-switch .ant-switch-handle::before,
.address-list-form .ant-modal-content .address-list-content .ant-switch .ant-switch-handle::before {
    background-color: #0A1E5B;
}

.address-list-form .ant-modal-content .address-list-content .switch-text {
    font-size: 18px;
    color: #7F8B97;
    font-family: 'FH-Oscar-Regular';
    letter-spacing: 0.5px;
}

.address-list-form .ant-modal-content .address-list-content .switch-text.active {
    color: #0A1E5B;
}

.address-list-form .ant-modal-content .ant-space.switch-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.address-list-form .ant-modal-content .ant-radio-group,
.address-list-form .ant-modal-content .ant-radio-button-wrapper {
    width: 100%;
}

.address-list-form .ant-modal-content .ant-radio-button-wrapper-checked {
    border: 1px solid #0A1E5B;
}

@media (max-width: 1300px){
    .new-submittal-page .add-products .content >.ant-row{
        flex-direction: column;
    }
    .new-submittal-page .add-products .content >.ant-row >.ant-col{
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .new-submittal-page .quote-request-confirmation-page .content {
        width: 900px;
    }
}

@media (max-width: 768px) {
    .new-submittal-page .quote-request-confirmation-page .content {
        width: 700px;
    }
}

@media (max-width: 575px) {
    .new-submittal-page .add-products .content .pagination-content {
        padding-left: 20px;
    }
}

@media (max-width: 425px) {
    .new-submittal-page .form-title {
        padding-left: 20px;
        font-size: 30px;
        display: flex;
    }
    .new-submittal-page .add-products .content-wrapper,
    .new-submittal-page .project-summary,
    .new-submittal-page .submittal-info,
    .new-submittal-page .cover-letter-upload,
    .new-submittal-page .quote-request-confirmation-page {
        padding: 0px 20px;
    }
    .new-submittal-page .cover-letter-upload .btn-content-wrapper .ant-row .ant-col:nth-child(2) {
        order: 2;
        display: flex;
        justify-content: flex-end;
    }
    .new-submittal-page .cover-letter-upload .btn-content-wrapper .skip-btn {
        width: 140px;
    }
    .new-submittal-page .cover-letter-upload .btn-content-wrapper .back-btn,
    .new-submittal-page .cover-letter-upload .btn-content-wrapper .next-btn,
    .new-submittal-page .cover-letter-upload .btn-content-wrapper .skip-btn {
        padding: 20px 10px;
    }
    .new-submittal-page .add-products .content .pagination-content {
        margin-right: 0px;
    }
    .new-submittal-page .project-summary .btn-content-wrapper .ant-space .purple-btn {
        padding: 25px 20px;
    }
    .new-submittal-page .project-summary .btn-content-wrapper .ant-space .next-btn {
        padding: 25px 20px;
    }
    .new-submittal-page .quote-request-confirmation-page .content {
        width: 380px;
    }
    .new-submittal-page .quote-request-confirmation-page .content .purple-btn {
        padding: 25px 20px;
    }
    .new-submittal-page .quote-request-confirmation-page .content .purple-btn span {
        font-size: 16px;
    }
    .new-submittal-page .quote-request-confirmation-page .content p,
    .new-submittal-page .quote-request-confirmation-page .content span {
        font-size: 30px;
    }
    .new-submittal-page .quote-request-confirmation-page .content svg:first-child {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 375px) {
    .new-submittal-page .form-title,
    .new-submittal-page .page-title {
        font-size: 26px;
    }
    .new-submittal-page .submittal-info .btn-content-wrapper .back-btn,
    .new-submittal-page .submittal-info .btn-content-wrapper .next-btn {
        padding: 25px 20px;
    }
    .new-submittal-page .quote-request-confirmation-page .content {
        width: 340px;
    }
}

@media (max-width: 320px) {
    .new-submittal-page .form-title,
    .new-submittal-page .page-title {
        font-size: 22px;
    }
    .new-submittal-page .project-summary .btn-content-wrapper .ant-space {
        display: flex;
        flex-direction: column;
    }
    .new-submittal-page .project-summary .btn-content-wrapper .ant-space .purple-btn {
        margin-bottom: 15px;
    }
    .new-submittal-page .submittal-info .content .switch-text {
        font-size: 16px;
    }
    .new-submittal-page .quote-request-confirmation-page .content {
        width: 280px;
    }
    .new-submittal-page .quote-request-confirmation-page .content p {
        font-size: 26px;
    }
}