.account-content-wrapper {
    background: #ffff;
    border-radius: 10px;
    padding: 40px;
}

.account-content-wrapper .account-content {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 25px 40px;
}

.account-content-wrapper .account-content h4 {
    font-family: 'FH-Haydon-Regular';
    color: #1A3173;
    font-size: 22px;
    margin-bottom: 20px;
}

.account-content-wrapper .account-content .cover-upload-wrapper .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    height: 63px;
}

.account-content-wrapper .account-content .account-details {
    margin-top: 40px;
}

.account-content-wrapper .account-content .ant-form .save-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}

.account-content-wrapper .account-content .ant-form .update-profile-btn {
    margin-top: 20px;
    height: 50px;
    padding: 4px 25px;
}

@media (max-width: 425px) {
    .account-content-wrapper {
        border-radius: 0px;
    }
    .account-content-wrapper .account-content {
       border: none;
       padding: 0px;
    }
    
}